import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useSelector } from "react-redux";
import axios from "axios";
import AllPagesNavbar from "../Navbar";
import Loading from "../Loading";
import { BiSolidEditAlt } from "react-icons/bi";
import { useLocation } from "react-router-dom";

import "../../CSS/EngineMaster.css";
import jsPDF from "jspdf";
import ExcelJS from "exceljs";
import "jspdf-autotable"; // Importing the AutoTable plugin
import { BsFileEarmarkPdf } from "react-icons/bs";
import { RiFileExcel2Line } from "react-icons/ri";

const MYMILESCOVERED = () => {
  const location = useLocation();
  const menu = location.state ? location.state.menu : null;
  const BASEURL_GLOBAL = process.env.REACT_APP_BASEURL_GLOBAL;

  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const sclientSecret = window.sessionStorage.getItem("sclientSecret");
  const state = useSelector((state) => state?.loginreducer);
  const date = new Date().toISOString();
  const userPass = state?.user?.userCode;

  const [PassPROPELLERNO, setPassPROPELLERNO] = useState("");
  const [search, setSearch] = useState("");
  const [PropellerList, setPropellerList] = useState([]);
  const [seldivision, setSelDivision] = useState("");
  const userState = useSelector((state) => state.getUserState);
  const userLoggeduserCode = userState.user.userCode;

  const [isloading, setisloading] = useState(false);

  const currentDate = new Date();
  const startOfYear = new Date(currentDate.getFullYear(), 0, 1);
  const localStartOfYear = new Date(
    startOfYear.getTime() - startOfYear.getTimezoneOffset() * 60000
  );

  const [startdate, setStartdate] = useState(localStartOfYear.toISOString());
  const [enddate, setEnddate] = useState(currentDate.toISOString());

  useEffect(() => {
    if (userLoggeduserCode) {
      FetchDetails();
    }
  }, [userLoggeduserCode]);
  function FetchDetails() {
    setisloading(true);
    axios
      // .get(`${BASEURL}/getMilesCovered?PILOT CODE=${userLoggeduserCode}`, {
      .get(
        `${BASEURL_GLOBAL}/globalViewHandler?viewname=271&PILOT CODE=${userLoggeduserCode}`,
        {
          headers: {
            "auth-token": authToken,
            "session-token": sclientSecret,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        setisloading(false);
        setPropellerList(res?.data);
      })
      .catch((err) => {
        console.log("Error while fetching /getPropellers List", err);
      });
    axios
      .get(`${BASEURL}/getMilesCovered?PILOT CODE=${userLoggeduserCode}`, {
        headers: {
          "auth-token": authToken,
          "session-token": sclientSecret,
        },
      })
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => {
        console.log("Error while fetching /getPropellers List", err);
      });
  }
  const filteredData = PropellerList.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);
    const sDate = new Date(item["TECHLOG DATE"]);
    const eDate = new Date(item["TECHLOG DATE"]);

    const textMatch = searchData.every((term) => {
      return ["TAKEOFFSANDLANDINGS", "TECHLOG DATE"].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });

    const dateMatch =
      (!startdate || sDate >= new Date(startdate)) &&
      (!enddate || eDate <= new Date(enddate));

    return textMatch && dateMatch;
  });

  const ExcelTechSheetData = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("MY MILES COVERED");

    // Set column widths for the worksheet
    const columnWidths = [15, 15, 20, 20, 20, 20, 20, 15, 15];
    columnWidths.forEach((width, index) => {
      worksheet.getColumn(index + 1).width = width;
    });

    // Add headers
    const headerRow = worksheet.addRow([
      "TAKE OFFS AND LANDINGS",
      "TECHLOG DATE",
    ]);

    // Bold the header row
    headerRow.eachCell((cell) => {
      cell.font = { bold: true };
    });

    // Add rows from the filteredData
    filteredData.forEach((item) => {
      const rowData = [
        item["TAKEOFFSANDLANDINGS"] || "NA",

        item["TECHLOG DATE"]
          ? `${item["TECHLOG DATE"].slice(8, 10)}/${item["TECHLOG DATE"].slice(
              5,
              7
            )}/${item["TECHLOG DATE"].slice(0, 4)}`
          : "NA",
      ];

      worksheet.addRow(rowData);
    });

    // Create a blob from the workbook and trigger download
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "MY_MILE_COVERED.xlsx";
      link.click();
    });
  };

  const handleGeneratePDF = () => {
    const doc = new jsPDF();
    doc.setFontSize(16);
    doc.text("MY MILES COVERED", 14, 20);

    const tableColumn = [
      { title: "TAKE OFFS AND LANDINGS", dataKey: "TAKEOFFSANDLANDINGS" },

      { title: "TECHLOG DATE", dataKey: "TECHLOG DATE" },
    ];

    const tableRows = filteredData.map((item) => [
      item["TAKEOFFSANDLANDINGS"] || "NA",

      item["TECHLOG DATE"]
        ? `${item["TECHLOG DATE"].slice(8, 10)}/${item["TECHLOG DATE"].slice(
            5,
            7
          )}/${item["TECHLOG DATE"].slice(0, 4)}`
        : "NA",
    ]);

    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 30,
      theme: "grid",
      styles: { cellPadding: 3, fontSize: 10 },
      columnStyles: {
        "MY MILES COVERED": { halign: "right" },
        "TECHLOG DATE": { halign: "left" },
      },
    });

    doc.save("MY_MILES_COVERED.pdf");
  };
  if (isloading) {
    return <Loading />;
  }

  return (
    <div className="bg-gray-50 min-h-[100vh]">
      <AllPagesNavbar />
      <header className="header__of__main lg:flex justify-between items-end px-3">
        <h1 className="header__of__page">{menu}</h1>
        <div className="flex items-center gap-5 my-1">
          {" "}
          <div
            onClick={ExcelTechSheetData}
            className="border-l-indigo-900 rounded mx-2 text-blue-950 cursor-pointer"
          >
            <RiFileExcel2Line size={35} />{" "}
          </div>
          <div
            onClick={handleGeneratePDF}
            className="border-l-indigo-900 rounded mx-2 text-blue-950 cursor-pointer"
          >
            <BsFileEarmarkPdf size={35} />
          </div>
        </div>{" "}
      </header>
      <section className="lg:flex items-center gap-5 mx-5">
        <div className="lg:flex items-center gap-3">
          <p>Search:</p>
          <input
            className="w-[300px] h-7 border border-black p-2 rounded-md focus:outline-none focus:border-blue-500"
            type="text"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        </div>
        <div className="lg:flex items-center gap-3">
          <label className=" text-gray-700">From Date</label>
          <input
            type="date"
            onChange={(e) => {
              setStartdate(e.target.value);
            }}
            value={startdate.split("T")[0]}
            placeholder="From Date"
          />
        </div>
        <div className="lg:flex items-center gap-3">
          <label className=" text-gray-700">End Date</label>
          <input
            type="date"
            onChange={(e) => {
              setEnddate(e.target.value);
            }}
            value={enddate.split("T")[0]}
            placeholder="To Date"
          />
        </div>
      </section>
      {isloading ? (
        <Loading />
      ) : (
        <div className="overflow-y-auto h-[500px] px-4">
          <table className="custom-table text-sm overflow__y__ relative ">
            <thead
              className="header-cell sticky top-[0px] left-[10px]"
              style={{ zIndex: 50 }}
            >
              <tr>
                <td className=" pr-5 text-right">
                  <p>TAKE OFFS AND LANDINGS</p>
                </td>
                <td className="">TECHLOG DATE</td>
              </tr>
            </thead>
            <tbody>
              {filteredData.length > 0 &&
                filteredData.map((item, index) => (
                  <tr
                    key={index}
                    style={{
                      textTransform: "uppercase",
                    }}
                  >
                    <td className=" text-right">
                      {item["TAKEOFFSANDLANDINGS"]
                        ? item["TAKEOFFSANDLANDINGS"]
                        : "NA"}
                    </td>

                    <td className="">
                      {item["TECHLOG DATE"]
                        ? item["TECHLOG DATE"].slice(8, 10) +
                          "/" +
                          item["TECHLOG DATE"].slice(5, 7) +
                          "/" +
                          item["TECHLOG DATE"].slice(0, 4)
                        : "NA"}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default MYMILESCOVERED;
