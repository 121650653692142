import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import AllPagesNavbar from "./Navbar";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { BiSolidEditAlt } from "react-icons/bi";
import jsPDF from "jspdf";
import ExcelJS from "exceljs";

import "jspdf-autotable";

import { BsFileEarmarkPdf } from "react-icons/bs";
import { RiFileExcel2Line } from "react-icons/ri";

const NONFLYINGTASKSALLOCATEDTOPILOTS = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const BASEURL_GLOBAL = process.env.REACT_APP_BASEURL_GLOBAL;

  const authToken = window.sessionStorage.getItem("auth-token");
  const sclientSecret = window.sessionStorage.getItem("sclientSecret");
  const [employeeName, setEmployeeName] = useState("");
  const [taskOptions, setTaskOptions] = useState([]); // Task options for multi-select
  const [selectedTasks, setSelectedTasks] = useState([]); // Selected tasks from multi-select
  const [startDateTime, setStartDateTime] = useState("");
  const [endDateTime, setEndDateTime] = useState("");
  const [hours, setHours] = useState("0");
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const [listofEmployee, setlistofEmployee] = useState([]);
  const [SelEmployeeCode, setSelEmployeeCode] = useState("");
  const [SelEmployeeName, setSelEmployeeName] = useState("");

  const menu = location.state ? location.state.menu : null;
  const [HoursAllocatedEmployeee, setHoursAllocatedEmployeee] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [newTaskIds, setNewTaskIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const userState = useSelector((state) => state.getUserState);
  const userLoggeduserCode = userState.user.userCode;
  console.log(userLoggeduserCode);
  const currentDate = new Date();
  const startOfYear = new Date(currentDate.getFullYear(), 0, 1);
  const localStartOfYear = new Date(
    startOfYear.getTime() - startOfYear.getTimezoneOffset() * 60000
  );

  const [startdate, setStartdate] = useState("");
  const [enddate, setEnddate] = useState("");
  const [search, setSearch] = useState("");

  // Function to fetch hours allocated to tasks
  const fetchHoursAllocated = () => {
    setLoading(true);
    axios
      // .get(`${BASEURL}/getHoursAllocatedToTasks`, {
      .get(`${BASEURL_GLOBAL}/globalViewHandler?viewname=420`, {
        headers: {
          "auth-token": authToken,
          "session-token": sclientSecret,
        },
      })
      .then((res) => {
        setLoading(false);
        console.log(res.data);

        setHoursAllocatedEmployeee(res.data); // Assuming response data is the hours allocated
      })
      .catch((err) => {
        setLoading(false);
        console.log("Error while fetching /getHoursAllocatedToTasks", err);
      });
    axios
      // .get(`${BASEURL}/getallemployee`, {
      .get(`${BASEURL_GLOBAL}/globalViewHandler?viewname=349`, {
        headers: {
          "auth-token": authToken,
          "session-token": sclientSecret,
        },
      })
      .then((response) => {
        console.log(response.data);
        setlistofEmployee(response.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log("Error while fetching /getEngines List", err);
      });
  };
  console.log(taskOptions);

  // Function to fetch tasks
  const fetchTasks = () => {
    setLoading(true);
    axios
      // .get(`${BASEURL}/getTasks`, {
      .get(`${BASEURL_GLOBAL}/globalViewHandler?viewname=532`, {
        headers: {
          "auth-token": authToken,
          "session-token": sclientSecret,
        },
      })
      .then((res) => {
        setLoading(false);
        const taskS = res.data.map((taskS) => ({
          value: taskS.TASKNAME,
          label: taskS.TASKNAME,
        }));
        console.log(res.data);
        setTaskOptions(taskS);
        setTasks(res.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log("Error while fetching /getTasks", err);
      });
  };

  // Function to fetch new task IDs
  const fetchNewTaskIds = () => {
    setLoading(true);
    axios
      // .get(`${BASEURL}/getNewTaskIDs`, {
      .get(`${BASEURL_GLOBAL}/globalViewHandler?viewname=476`, {
        headers: {
          "auth-token": authToken,
          "session-token": sclientSecret,
        },
      })
      .then((res) => {
        setLoading(false);
        console.log(res.data[0]?.NEWTASK);

        setNewTaskIds(res.data[0]?.NEWTASK);
      })
      .catch((err) => {
        setLoading(false);
        console.log("Error while fetching /getNewTaskIDs", err);
      });
  };

  // Fetch all data when the component mounts
  useEffect(() => {
    fetchHoursAllocated();
    fetchTasks();
    fetchNewTaskIds();
  }, []);
  // Function to calculate hours dynamically
  const starttt = new Date(startDateTime);
  console.log(starttt);

  const calculateHours = () => {
    const start = new Date(startDateTime);
    const end = new Date(endDateTime);

    // Validation: Ensure start time is before end time
    if (start >= end) {
      alert(
        "Start date and time cannot be greater than or equal to end date and time"
      );
      setEndDateTime("");
      setHours("");

      return;
    }
    if (end <= start) {
      alert(
        "Start date and time cannot be greater than or equal to end date and time"
      );
      setEndDateTime("");
      setHours("");

      return;
    }

    // Calculate hours
    const diffMs = end - start; // Difference in milliseconds
    const diffHrs = (diffMs / (1000 * 60 * 60)).toFixed(2); // Convert ms to hours
    setHours(diffHrs);
  };

  const [SelectedTasksCheckBox, setSelectedTasksCheckBox] = useState([]); // State to track selected tasks
  console.log(SelectedTasksCheckBox);

  // Function to handle checkbox selection and store entire row data
  const handleSelectTask = (rowData) => {
    const isSelected = SelectedTasksCheckBox.some(
      (task) =>
        task.DOCNO === rowData.DOCNO && task.TASKNAME === rowData.TASKNAME
    );

    if (isSelected) {
      // Remove the selected task
      setSelectedTasksCheckBox((prevTasks) =>
        prevTasks.filter(
          (task) =>
            !(
              task.DOCNO === rowData.DOCNO && task.TASKNAME === rowData.TASKNAME
            )
        )
      );
    } else {
      // Add the selected task (store the entire row data)
      setSelectedTasksCheckBox((prevTasks) => [...prevTasks, rowData]);
    }
  };

  // Function to handle task deletion
  const handleDeleteTasks = async () => {
    if (SelectedTasksCheckBox.length === 0) {
      alert("Please select at least one task to delete.");
      return;
    }

    const confirmDelete = window.confirm(
      "Are you sure you want to delete selected tasks?"
    );
    if (!confirmDelete) {
      return;
    }

    setIsLoading(true);
    try {
      const deleteRequests = SelectedTasksCheckBox.map((task) => {
        const deleteData = {
          TRANSACTIONNO: Number(task.DOCNO),
          TASKNAME: task.TASKNAME,
          TASKALLOCATEDTO: task.EMPCODE,
        };

        return axios.post(
          // `${BASEURL}/deleteEmployeeTask`, deleteData, {
          `${BASEURL_GLOBAL}/globalSpHandler?spname=81 `,
          deleteData,
          {
            headers: {
              "auth-token": authToken,
              "session-token": sclientSecret,
            },
          }
        );
      });

      await Promise.all(deleteRequests);
      alert("Selected tasks deleted successfully!");
      window.location.reload();
      setSelectedTasksCheckBox([]); // Clear selected tasks after deletion
    } catch (error) {
      console.error("Error while deleting tasks:", error);
      alert("An error occurred while deleting the tasks.");
    } finally {
      setIsLoading(false);
    }
  };

  //filter
  const filteredData = HoursAllocatedEmployeee.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);
    const sDate = new Date(item["STARTTIME"].slice(0, 10));
    const eDate = new Date(item["STARTTIME"].slice(0, 10));

    const textMatch = searchData.every((term) => {
      return [
        "DOCNO",
        "TASKNAME",
        "EMPCODE",
        "EMPNAME",
        "STARTTIME",
        "ENDTIME",
        "HOURSTAKEN",
        "CREATEDBY",
        "CREATEDATE",
      ].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });

    const dateMatch =
      (!startdate || sDate >= new Date(startdate)) &&
      (!enddate || eDate <= new Date(enddate));

    return textMatch && dateMatch;
  });
  console.log(HoursAllocatedEmployeee);

  // Function to handle inserting tasks
  const handleInsertTasks = async () => {
    if (!newTaskIds) {
      alert("Please Provide Transaction No");
      return;
    }
    if (!SelEmployeeCode) {
      alert("Please select Employee");
      return;
    }
    if (!startDateTime) {
      alert("Please Provide Start Time");
      return;
    }
    if (!endDateTime) {
      alert("Please Provide End Time");
      return;
    }

    if (selectedTasks.length === 0) {
      alert("Please select at least one task to save.");
      return;
    }
    const confirmInsert = window.confirm("Do you want to save new tasks?");
    if (!confirmInsert) {
      return;
    }
    setIsLoading(true);
    try {
      const insertRequests = selectedTasks.map((task) => {
        const insertData = {
          TRANSACTIONNO: Number(newTaskIds),
          TASKNAME: task.value,
          TASKALLOCATEDTO: SelEmployeeCode,
          TASKSTARTDATETIME: startDateTime ? startDateTime + ":00.000Z" : "",
          TASKENDTIME: endDateTime ? endDateTime + ":00.000Z" : "",
          TASKHOURS: Number(hours),
          TASKCREATEDBY: userLoggeduserCode,
        };

        return axios.post(
          // `${BASEURL}/insertEmployeeTask`, insertData, {
          `${BASEURL_GLOBAL}/globalSpHandler?spname=131`,
          insertData,
          {
            headers: {
              "auth-token": authToken,
              "session-token": sclientSecret,
            },
          }
        );
      });

      await Promise.all(insertRequests);
      alert("Tasks inserted successfully!");
      window.location.reload();
    } catch (error) {
      console.error("Error while inserting tasks:", error);
      alert("An error occurred while inserting the tasks.");
    } finally {
      setIsLoading(false);
    }
  };
  function handleEdit(item) {
    setNewTaskIds(item.DOCNO);
    setSelEmployeeCode(item.EMPCODE);
    setSelEmployeeName(item.EMPNAME);
    setStartDateTime(item.STARTTIME.slice(0, 16));
    setEndDateTime(item.ENDTIME.slice(0, 16));
    setHours(item.HOURSTAKEN);

    // Ensure TASKNAME is treated as an array, even if it's a single string or undefined
    const taskNamesArray = Array.isArray(item.TASKNAME)
      ? item.TASKNAME
      : item.TASKNAME
      ? item.TASKNAME.split(",") // If it's a comma-separated string, split into array
      : [];

    // Map the task names into the format required for the multi-select options
    const taskSelections = taskNamesArray.map((task) => ({
      value: task.trim(), // Trim in case of extra spaces
      label: task.trim(),
    }));

    // Update the selected tasks state to show in multi-select
    setSelectedTasks(taskSelections);
  }
  console.log(startDateTime);
  console.log(endDateTime);

  const scrollToSection = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // Add this function to your TrainingType component
  const ExcelTechSheetData = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(
      "NON FLYING TASKS ALLOCATED TO PILOTS"
    );

    // Set column widths for the worksheet
    const columnWidths = [15, 40, 20, 30, 30, 30, 10, 30, 30];
    columnWidths.forEach((width, index) => {
      worksheet.getColumn(index + 1).width = width;
    });

    // Add headers
    const headerRow = worksheet.addRow([
      "DOC NO",
      "TASK NAME",
      "EMP CODE",
      "EMP NAME",
      "START TIME",
      "END TIME",
      "HOURS TAKEN",
      "CREATED BY",
      "CREATE DATE",
    ]);

    // Bold the header row
    headerRow.eachCell((cell) => {
      cell.font = { bold: true };
    });

    // Add rows from the filteredData
    filteredData.forEach((item) => {
      const rowData = [
        item["DOCNO"] || "NA",
        item["TASKNAME"] || "NA",
        item["EMPCODE"] || "NA",
        item["EMPNAME"] || "NA",

        item["STARTTIME"]
          ? `${item["STARTTIME"].slice(8, 10)}/${item["STARTTIME"].slice(
              5,
              7
            )}/${item["STARTTIME"].slice(0, 4)}` +
            " " +
            item["STARTTIME"].slice(11, 16)
          : "NA",
        item["STARTTIME"]
          ? `${item["ENDTIME"].slice(8, 10)}/${item["ENDTIME"].slice(
              5,
              7
            )}/${item["ENDTIME"].slice(0, 4)}` +
            " " +
            item["ENDTIME"].slice(11, 16)
          : "NA",
        item["HOURSTAKEN"] || "0",
        item["CREATEDBY"] || "0",
        item["CREATEDATE"]
          ? `${item["CREATEDATE"].slice(8, 10)}/${item["CREATEDATE"].slice(
              5,
              7
            )}/${item["CREATEDATE"].slice(0, 4)}` +
            " " +
            item["CREATEDATE"].slice(11, 16)
          : "NA",
      ];

      worksheet.addRow(rowData);
    });

    // Create a blob from the workbook and trigger download
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "NON_FLYING_TASKS_ALLOCATED_TO_PILOTS.xlsx";
      link.click();
    });
  };

  const handleGeneratePDF = () => {
    const doc = new jsPDF();
    doc.setFontSize(16);
    doc.text("NON FLYING TASKS ALLOCATED TO PILOTS", 14, 20);

    const tableColumn = [
      { title: "DOC NO", dataKey: "DOCNO" },
      { title: "TASK NAME", dataKey: "TASKNAME" },
      { title: "EMP CODE", dataKey: "EMPCODE" },
      { title: "EMP NAME", dataKey: "EMPNAME" },
      { title: "START TIME", dataKey: "STARTTIME" },
      { title: "END TIME", dataKey: "ENDTIME" },
      { title: "HOURS TAKEN", dataKey: "HOURSTAKEN" },
      { title: "CREATE DBY", dataKey: "CREATEDBY" },
      { title: "CREATE DATE", dataKey: "CREATEDATE" },
    ];

    const tableRows = filteredData.map((item) => [
      item["DOCNO"] || "NA",
      item["TASKNAME"] || "NA",
      item["EMPCODE"] || "NA",
      item["EMPNAME"] || "NA",

      item["STARTTIME"]
        ? `${item["STARTTIME"].slice(8, 10)}/${item["STARTTIME"].slice(
            5,
            7
          )}/${item["STARTTIME"].slice(0, 4)}` +
          " " +
          item["STARTTIME"].slice(11, 16)
        : "NA",
      item["STARTTIME"]
        ? `${item["ENDTIME"].slice(8, 10)}/${item["ENDTIME"].slice(
            5,
            7
          )}/${item["ENDTIME"].slice(0, 4)}` +
          " " +
          item["ENDTIME"].slice(11, 16)
        : "NA",
      item["HOURSTAKEN"] || "0",
      item["CREATEDBY"] || "0",
      item["CREATEDATE"]
        ? `${item["CREATEDATE"].slice(8, 10)}/${item["CREATEDATE"].slice(
            5,
            7
          )}/${item["CREATEDATE"].slice(0, 4)}` +
          " " +
          item["CREATEDATE"].slice(11, 16)
        : "NA",
    ]);

    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 30,
      theme: "grid",
      styles: { cellPadding: 3, fontSize: 6 },
      columnStyles: {
        DOCNO: { halign: "right" },
      },
    });

    doc.save("NONFLYINGTASKSALLOCATEDTOPILOTS.pdf");
  };
  return (
    <div className="bg-slate-50 newpages__input">
      <AllPagesNavbar />
      <header className="header__of__main lg:flex justify-between items-end px-3">
        <h1 className="header__of__page">
          {menu || "Non Flying Tasks Allocated to Pilots"}
        </h1>
        <div className="flex items-center gap-5 my-1 newpages__input">
          {" "}
          <button
            onClick={handleInsertTasks}
            disabled={isLoading}
            className="px-3"
          >
            {isLoading ? "Saveing..." : "Save Tasks"}
          </button>
        </div>
      </header>

      <div className="lg:w-[95%] mt-5 mx-auto p-6 bg-white rounded-lg shadow-lg flex flex-wrap justify-between gap-5">
        {/* Transaction No */}
        <div className="lg:mb-4 lg:w-[10%] w-full  ">
          <label className="  text-gray-700 font-medium mb-2">
            Transaction No:
          </label>
          <input
            type="text"
            value={newTaskIds}
            readOnly
            className=" newpages__input h-9  w-full   px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
          />
        </div>

        {/* Employee Name */}
        <div className="lg:mb-4 lg:w-[30%] w-full   ">
          <label className="  text-gray-700 font-medium mb-2">
            Employee Name:
          </label>

          <Select
            onChange={(selected) => {
              setSelEmployeeCode(selected.value);
              setSelEmployeeName(selected.label);
            }}
            value={{
              value: SelEmployeeCode,
              label: SelEmployeeName || "Select ",
            }}
            options={[
              { value: "", label: "Select .." },
              ...(listofEmployee
                ? listofEmployee.map((item) => ({
                    value: item.EMPCODE,
                    label: item.EMPNAME,
                  }))
                : []),
            ]}
            className="z-[100]"
          />
        </div>

        {/* Task Multi-Select */}
        <div className=" lg:w-[50%] w-full  ">
          <label className="  text-gray-700 font-medium mb-2">Task Type:</label>
          <Select
            isMulti
            options={taskOptions} // Task options for multi-select
            value={selectedTasks} // Selected tasks shown in the select component
            onChange={(selected) => setSelectedTasks(selected)} // Update selected tasks state
            placeholder="Select Tasks"
            className=" w-full  rounded-md"
          />
        </div>

        {/* Start Date and Time */}
        <div className="lg:mb-4 lg:w-[20%] w-full   ">
          <label className="  text-gray-700 font-medium mb-2">
            Start Date and Time:
          </label>
          <input
            type="datetime-local"
            value={startDateTime}
            onBlur={calculateHours} // Calculate hours when end time is set
            onChange={(e) => setStartDateTime(e.target.value)}
            className=" newpages__input h-9  w-full   px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
          />
        </div>

        {/* End Date and Time */}
        <div className="lg:mb-4 lg:w-[20%] w-full  ">
          <label className="  text-gray-700 font-medium mb-2">
            End Date and Time:
          </label>
          <input
            type="datetime-local"
            value={endDateTime}
            onChange={(e) => setEndDateTime(e.target.value)}
            onBlur={calculateHours} // Calculate hours when end time is set
            className=" newpages__input h-9  w-full   px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
          />
        </div>

        {/* Hours */}
        <div className="lg:mb-4 lg:w-[10%] w-full  ">
          <label className="  text-gray-700 font-medium mb-2">Hours:</label>
          <input
            type="text"
            value={hours === "NaN" ? 0 : hours ? hours : 0}
            readOnly
            className="  newpages__input h-9 w-full    px-4 py-2 border border-gray-300 rounded-md bg-gray-100 cursor-not-allowed"
          />
        </div>

        {/* Narration */}
        <div className="lg:mb-4 lg:w-[40%] w-full  "></div>
      </div>
      <section className="lg:flex items-center gap-5 mx-5 mt-5">
        <div className="lg:flex items-center gap-3">
          <p>Search:</p>
          <input
            className="w-[300px] h-7 border border-black p-2 rounded-md focus:outline-none focus:border-blue-500"
            type="text"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        </div>
        <div className="lg:flex items-center gap-3">
          <label className=" text-gray-700">From Date</label>
          <input
            type="date"
            onChange={(e) => {
              setStartdate(e.target.value);
            }}
            value={startdate.split("T")[0]}
            placeholder="From Date"
          />
        </div>
        <div className="lg:flex items-center gap-3">
          <label className=" text-gray-700">End Date</label>
          <input
            type="date"
            onChange={(e) => {
              setEnddate(e.target.value);
            }}
            value={enddate.split("T")[0]}
            placeholder="To Date"
          />
        </div>
        {/* Separate button to delete only */}
        <div
          onClick={ExcelTechSheetData}
          className="border-l-indigo-900 rounded mx-2 text-blue-950 cursor-pointer"
        >
          <RiFileExcel2Line size={35} />{" "}
        </div>
        <div
          onClick={handleGeneratePDF}
          className="border-l-indigo-900 rounded mx-2 text-blue-950 cursor-pointer"
        >
          <BsFileEarmarkPdf size={35} />
        </div>
        {/* Separate button to insert only */}
        <button
          onClick={handleDeleteTasks}
          disabled={isLoading}
          className="px-3 lg:ml-10 mt-2 lg:mt-0"
        >
          {isLoading ? "Deleting..." : "Delete Selected Tasks"}
        </button>
      </section>

      <div className="overflow-y-auto h-[500px] px-4">
        <table className="custom-table text-sm overflow__y__ relative ">
          <thead
            className="header-cell sticky top-[0px] left-[10px]"
            style={{ zIndex: 50 }}
          >
            <tr>
              <td className=" text-right"></td>
              <td className=" text-right">DOC NO</td>
              <td className="">TASK NAME</td>
              <td className="">EMPLOYEE CODE </td>
              <td className="">EMPLOYEE NAME </td>
              <td className="">START TIME </td>
              <td className="">END TIME </td>
              <td className="">HOURS TAKEN </td>
              <td className="">CREATED BY </td>
              <td className="">CREATE DATE </td>
              <td className="">Edit </td>
            </tr>
          </thead>
          <tbody>
            {filteredData.length > 0 &&
              filteredData.map((item, index) => (
                <tr
                  key={index}
                  style={{
                    textTransform: "uppercase",
                  }}
                >
                  <td>
                    <input
                      className="w-[20px]"
                      type="checkbox"
                      checked={SelectedTasksCheckBox.some(
                        (task) =>
                          task.DOCNO === item["DOCNO"] &&
                          task.TASKNAME === item["TASKNAME"]
                      )}
                      onChange={() => handleSelectTask(item)} // Pass the entire row data
                    />
                  </td>
                  <td className=" text-right">
                    {item["DOCNO"] ? item["DOCNO"] : "NA"}
                  </td>
                  <td>{item["TASKNAME"] ? item["TASKNAME"] : "NA"}</td>
                  <td>{item["EMPCODE"] ? item["EMPCODE"] : "NA"}</td>
                  <td>{item["EMPNAME"] ? item["EMPNAME"] : "NA"}</td>

                  <td className="">
                    {item["STARTTIME"]
                      ? item["STARTTIME"].slice(8, 10) +
                        "/" +
                        item["STARTTIME"].slice(5, 7) +
                        "/" +
                        item["STARTTIME"].slice(0, 4) +
                        "  " +
                        item["STARTTIME"].slice(11, 16)
                      : "NA"}
                  </td>
                  <td className="">
                    {item["ENDTIME"]
                      ? item["ENDTIME"].slice(8, 10) +
                        "/" +
                        item["ENDTIME"].slice(5, 7) +
                        "/" +
                        item["ENDTIME"].slice(0, 4) +
                        "  " +
                        item["ENDTIME"].slice(11, 16)
                      : "NA"}
                  </td>
                  <td className=" text-right">
                    {item["HOURSTAKEN"] ? item["HOURSTAKEN"] : "NA"}
                  </td>
                  <td>{item["CREATEDBY"] ? item["CREATEDBY"] : "NA"}</td>

                  <td className="">
                    {item["CREATEDATE"]
                      ? item["CREATEDATE"].slice(8, 10) +
                        "/" +
                        item["CREATEDATE"].slice(5, 7) +
                        "/" +
                        item["CREATEDATE"].slice(0, 4) +
                        "  " +
                        item["CREATEDATE"].slice(11, 16)
                      : "NA"}
                  </td>
                  <td
                    className="w-[100px]"
                    style={{
                      cursor: "pointer",
                      textDecoration: "underline",
                      fontSize: "22px",
                    }}
                    onClick={() => {
                      handleEdit(item);
                      scrollToSection();
                    }}
                  >
                    <BiSolidEditAlt />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default NONFLYINGTASKSALLOCATEDTOPILOTS;
