import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useSelector } from "react-redux";
import axios from "axios";
import AllPagesNavbar from "../Navbar";
import Loading from "../Loading";
import { BiSolidEditAlt } from "react-icons/bi";
import { useLocation } from "react-router-dom";
import ExcelJS from "exceljs";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import "../../CSS/EngineMaster.css";
import { BsFileEarmarkPdf } from "react-icons/bs";
import { RiFileExcel2Line } from "react-icons/ri";
const MYTAKEOFFSANDLANDINGS = () => {
  const location = useLocation();
  const menu = location.state ? location.state.menu : null;
  const BASEURL_GLOBAL = process.env.REACT_APP_BASEURL_GLOBAL;

  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const sclientSecret = window.sessionStorage.getItem("sclientSecret");
  const state = useSelector((state) => state?.loginreducer);
  const date = new Date().toISOString();
  const userPass = state?.user?.userCode;

  const [PassPROPELLERNO, setPassPROPELLERNO] = useState("");
  const [search, setSearch] = useState("");
  const [PropellerList, setPropellerList] = useState([]);
  const [seldivision, setSelDivision] = useState("");
  const userState = useSelector((state) => state.getUserState);
  const userLoggeduserCode = userState.user.userCode;

  const [isloading, setisloading] = useState(false);

  const currentDate = new Date();
  const startOfYear = new Date(currentDate.getFullYear(), 0, 1);
  const localStartOfYear = new Date(
    startOfYear.getTime() - startOfYear.getTimezoneOffset() * 60000
  );

  const [startdate, setStartdate] = useState(localStartOfYear.toISOString());
  const [enddate, setEnddate] = useState(currentDate.toISOString());

  const [PassLASTOVERREADING, setPassLASTOVERREADING] = useState("");
  const [PassLASTOVERHAULDATE, setPassLASTOVERHAULDATE] = useState("");

  useEffect(() => {
    if (userLoggeduserCode) {
      FetchDetails();
    }
  }, [userLoggeduserCode]);
  function FetchDetails() {
    setisloading(true);
    axios
      .get(
        // `${BASEURL}/getHistoricPilotSchedules?PILOT CODE=${userLoggeduserCode}`,
        `${BASEURL_GLOBAL}/globalViewHandler?viewname=271&PILOT CODE=${userLoggeduserCode}`,
        {
          headers: {
            "auth-token": authToken,
            "session-token": sclientSecret,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        setisloading(false);
        setPropellerList(res?.data);
      })
      .catch((err) => {
        console.log("Error while fetching /getPropellers List", err);
      });
  }
  const filteredData = PropellerList.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);

    // Convert all dates to Date objects for filtering
    const sDate = new Date(item["TECHLOG DATE"]);
    const eDate = new Date(item["TECHLOG DATE"]);

    // Text Match - check if any search term matches any field
    const textMatch = searchData.every((term) => {
      return [
        "AIRCRAFT",
        "BLOCK TIME HOURS",
        "BLOCK TIME MINUTES",
        "CHECK OFF",
        "CHECK ON",
        "CREATED DATE",
        "ENGINE CYCLES",
        "ENGINESERIAL",
        "FLYING HRS",
        "FROM",
        "FUEL LH",
        "FUEL RH",
        "LANDING TIME",
        "LEGINDEX",
        "MILES",
        "MILES/HOUR",
        "MINUTES",
        "PERIOD",
        "PILOT CODE",
        "PILOT NAME",
        "PROPELLER1SERIAL",
        "PROPELLER2SERIAL",
        "SERIALNUMBER",
        "TAKE OFF TIME",
        "TECH LOG NO",
        "TECHLOG DATE",
        "TECHLOG TYPE",
        "TO",
        "TOTAL FUEL",
      ].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });

    // Date Match - check if date is within range
    const dateMatch =
      (!startdate || sDate >= new Date(startdate)) &&
      (!enddate || eDate <= new Date(enddate));

    // Return true if both text and date match
    return textMatch && dateMatch;
  });

  const generateAircraftPDF = () => {
    const doc = new jsPDF();
    doc.setFontSize(6); // Set font size to 6

    // Title for the first table
    doc.text("Aircraft Data - Part 1", 10, 10);

    const headers1 = [
      "AIRCRAFT",
      "BLOCK TIME HOURS",
      "BLOCK TIME MINUTES",
      "CHECK OFF",
      "CHECK ON",
      "CREATED DATE",
      "ENGINE CYCLES",
      "ENGINE SERIAL",
      "FLYING HRS",
    ];

    const rows1 = filteredData.map((item) => [
      item["AIRCRAFT"] || "NA",
      item["BLOCK TIME HOURS"] || "NA",
      item["BLOCK TIME MINUTES"] || "NA",
      item["CHECK OFF"] ? item["CHECK OFF"].slice(11, 19) : "NA",
      item["CHECK ON"] ? item["CHECK ON"].slice(11, 19) : "NA",
      item["CREATED DATE"]
        ? `${item["CREATED DATE"].slice(8, 10)}/${item["CREATED DATE"].slice(
            5,
            7
          )}/${item["CREATED DATE"].slice(0, 4)}`
        : "NA",
      item["ENGINE CYCLES"] || "NA",
      item["ENGINESERIAL"] || "NA",
      item["FLYING HRS"] || "NA",
    ]);

    // Set column widths for the first table
    const columnWidths1 = [20, 20, 20, 15, 15, 25, 15, 20, 15];

    doc.autoTable({
      head: [headers1],
      body: rows1,
      startY: 20,
      styles: { fontSize: 6 },
      columnStyles: {
        0: { cellWidth: columnWidths1[0] },
        1: { cellWidth: columnWidths1[1] },
        2: { cellWidth: columnWidths1[2] },
        3: { cellWidth: columnWidths1[3] },
        4: { cellWidth: columnWidths1[4] },
        5: { cellWidth: columnWidths1[5] },
        6: { cellWidth: columnWidths1[6] },
        7: { cellWidth: columnWidths1[7] },
        8: { cellWidth: columnWidths1[8] },
      },
    });

    // Add space between tables
    const nextY = doc.autoTable.previous.finalY + 10;

    // Title for the second table
    doc.text("Aircraft Data - Part 2", 10, nextY);

    const headers2 = [
      "FROM",
      "FUEL LH",
      "FUEL RH",
      "LANDING TIME",
      "LEGINDEX",
      "MILES",
      "MILES/HOUR",
      "MINUTES",
      "PERIOD",
      "PILOT CODE",
      "PILOT NAME",
      "PROPELLER1 SERIAL",
      "PROPELLER2 SERIAL",
      "SERIAL NUMBER",
      "TAKE OFF TIME",
      "TECH LOG NO",
      "TECHLOG DATE",
      "TECHLOG TYPE",
      "TO",
      "TOTAL FUEL",
    ];

    const rows2 = filteredData.map((item) => [
      item["FROM"] || "NA",
      item["FUEL LH"] || "NA",
      item["FUEL RH"] || "NA",
      item["LANDING TIME"] ? item["LANDING TIME"].slice(11, 19) : "NA",
      item["LEGINDEX"] || "NA",
      item["MILES"] || "NA",
      item["MILES/HOUR"] !== undefined && item["MILES/HOUR"] !== null
        ? item["MILES/HOUR"].toFixed(2)
        : "NA",
      item["MINUTES"] || "NA",
      item["PERIOD"] || "NA",
      item["PILOT CODE"] || "NA",
      item["PILOT NAME"] || "NA",
      item["PROPELLER1SERIAL"] || "NA",
      item["PROPELLER2SERIAL"] || "NA",
      item["SERIALNUMBER"] || "NA",
      item["TAKE OFF TIME"] ? item["TAKE OFF TIME"].slice(11, 19) : "NA",
      item["TECH LOG NO"] || "NA",
      item["TECHLOG DATE"]
        ? `${item["TECHLOG DATE"].slice(8, 10)}/${item["TECHLOG DATE"].slice(
            5,
            7
          )}/${item["TECHLOG DATE"].slice(0, 4)}`
        : "NA",
      item["TECHLOG TYPE"] || "NA",
      item["TO"] || "NA",
      item["TOTAL FUEL"] || "NA",
    ]);

    // Set column widths for the second table
    const columnWidths2 = [
      15, 15, 15, 15, 15, 15, 20, 15, 15, 15, 15, 20, 20, 20, 20, 20, 20, 20,
      20, 20,
    ];

    doc.autoTable({
      head: [headers2],
      body: rows2,
      startY: nextY + 10,
      styles: { fontSize: 6 },
      columnStyles: {
        0: { cellWidth: columnWidths2[0] },
        1: { cellWidth: columnWidths2[1] },
        2: { cellWidth: columnWidths2[2] },
        3: { cellWidth: columnWidths2[3] },
        4: { cellWidth: columnWidths2[4] },
        5: { cellWidth: columnWidths2[5] },
        6: { cellWidth: columnWidths2[6] },
        7: { cellWidth: columnWidths2[7] },
        8: { cellWidth: columnWidths2[8] },
        9: { cellWidth: columnWidths2[9] },
        10: { cellWidth: columnWidths2[10] },
        11: { cellWidth: columnWidths2[11] },
        12: { cellWidth: columnWidths2[12] },
        13: { cellWidth: columnWidths2[13] },
        14: { cellWidth: columnWidths2[14] },
        15: { cellWidth: columnWidths2[15] },
        16: { cellWidth: columnWidths2[16] },
        17: { cellWidth: columnWidths2[17] },
        18: { cellWidth: columnWidths2[18] },
        19: { cellWidth: columnWidths2[19] },
      },
    });

    // Save PDF
    doc.save("Aircraft_Data_Report.pdf");
  };

  const generateAircraftExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Aircraft Data");

    // Set column widths
    const columnWidths = [
      20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20,
      20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20,
    ];
    columnWidths.forEach((width, index) => {
      worksheet.getColumn(index + 1).width = width;
    });

    // Add headers
    const headers = [
      "AIRCRAFT",
      "BLOCK TIME HOURS",
      "BLOCK TIME MINUTES",
      "CHECK OFF",
      "CHECK ON",
      "CREATED DATE",
      "ENGINE CYCLES",
      "ENGINE SERIAL",
      "FLYING HRS",
      "FROM",
      "FUEL LH",
      "FUEL RH",
      "LANDING TIME",
      "LEGINDEX",
      "MILES",
      "MILES/HOUR",
      "MINUTES",
      "PERIOD",
      "PILOT CODE",
      "PILOT NAME",
      "PROPELLER1 SERIAL",
      "PROPELLER2 SERIAL",
      "SERIAL NUMBER",
      "TAKE OFF TIME",
      "TECH LOG NO",
      "TECHLOG DATE",
      "TECHLOG TYPE",
      "TO",
      "TOTAL FUEL",
    ];
    worksheet.addRow(headers).font = { bold: true };

    // Add data
    filteredData.forEach((item) => {
      worksheet.addRow([
        item["AIRCRAFT"] || "NA",
        item["BLOCK TIME HOURS"] || "NA",
        item["BLOCK TIME MINUTES"] || "NA",
        item["CHECK OFF"] ? item["CHECK OFF"].slice(11, 19) : "NA",
        item["CHECK ON"] ? item["CHECK ON"].slice(11, 19) : "NA",
        item["CREATED DATE"]
          ? `${item["CREATED DATE"].slice(8, 10)}/${item["CREATED DATE"].slice(
              5,
              7
            )}/${item["CREATED DATE"].slice(0, 4)}`
          : "NA",
        item["ENGINE CYCLES"] || "NA",
        item["ENGINESERIAL"] || "NA",
        item["FLYING HRS"] || "NA",
        item["FROM"] || "NA",
        item["FUEL LH"] || "NA",
        item["FUEL RH"] || "NA",
        item["LANDING TIME"] ? item["LANDING TIME"].slice(11, 19) : "NA",
        item["LEGINDEX"] || "NA",
        item["MILES"] || "NA",
        item["MILES/HOUR"] !== undefined && item["MILES/HOUR"] !== null
          ? item["MILES/HOUR"].toFixed(2)
          : "NA",
        item["MINUTES"] || "NA",
        item["PERIOD"] || "NA",
        item["PILOT CODE"] || "NA",
        item["PILOT NAME"] || "NA",
        item["PROPELLER1SERIAL"] || "NA",
        item["PROPELLER2SERIAL"] || "NA",
        item["SERIALNUMBER"] || "NA",
        item["TAKE OFF TIME"] ? item["TAKE OFF TIME"].slice(11, 19) : "NA",
        item["TECH LOG NO"] || "NA",
        item["TECHLOG DATE"]
          ? `${item["TECHLOG DATE"].slice(8, 10)}/${item["TECHLOG DATE"].slice(
              5,
              7
            )}/${item["TECHLOG DATE"].slice(0, 4)}`
          : "NA",
        item["TECHLOG TYPE"] || "NA",
        item["TO"] || "NA",
        item["TOTAL FUEL"] || "NA",
      ]);
    });

    // Download the Excel file
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "Aircraft_Data_Report.xlsx";
      document.body.appendChild(link); // Append to body
      link.click(); // Simulate click
      document.body.removeChild(link); // Remove from body
    });
  };

  const scrollToSection = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  if (isloading) {
    return <Loading />;
  }

  return (
    <div className="bg-gray-50 min-h-[100vh]">
      <AllPagesNavbar />
      <header className="header__of__main lg:flex justify-between items-end px-3">
        <h1 className="header__of__page">{menu}</h1>
      </header>
      <section className="lg:flex items-center gap-5 mx-5">
        <div className="lg:flex items-center gap-3">
          <p>Search:</p>
          <input
            className="w-[300px] h-7 border border-black p-2 rounded-md focus:outline-none focus:border-blue-500"
            type="text"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        </div>
        <div className="lg:flex items-center gap-3">
          <label className=" text-gray-700">From Date</label>
          <input
            type="date"
            onChange={(e) => {
              setStartdate(e.target.value);
            }}
            value={startdate.split("T")[0]}
            placeholder="From Date"
          />
        </div>
        <div className="lg:flex items-center gap-3">
          <label className=" text-gray-700">End Date</label>
          <input
            type="date"
            onChange={(e) => {
              setEnddate(e.target.value);
            }}
            value={enddate.split("T")[0]}
            placeholder="To Date"
          />
        </div>
        <div
          onClick={generateAircraftPDF}
          className="border-l-indigo-900 rounded mx-2 "
        >
          <BsFileEarmarkPdf size={35} />
        </div>
        <div
          onClick={generateAircraftExcel}
          className="border-l-indigo-900 rounded mx-2 "
        >
          <RiFileExcel2Line size={35} />{" "}
        </div>
      </section>
      {isloading ? (
        <Loading />
      ) : (
        <div className="overflow-y-auto h-[500px] px-4">
          <table className="custom-table text-sm overflow__y__ relative ">
            <thead
              className="header-cell sticky top-[0px] left-[10px]"
              style={{ zIndex: 50 }}
            >
              <tr>
                <td>AIRCRAFT</td>
                <td>BLOCK TIME HOURS</td>
                <td>BLOCK TIME MINUTES</td>
                <td>CHECK OFF</td>
                <td>CHECK ON</td>
                <td>CREATED DATE</td>
                <td>ENGINE CYCLES</td>
                <td>ENGINE SERIAL</td>
                <td>FLYING HRS</td>
                <td>FROM</td>
                <td>FUEL LH</td>
                <td>FUEL RH</td>
                <td>LANDING TIME</td>
                <td>LEGINDEX</td>
                <td>MILES</td>
                <td>MILES/HOUR</td>
                <td>MINUTES</td>
                <td>PERIOD</td>
                <td>PILOT CODE</td>
                <td>PILOT NAME</td>
                <td>PROPELLER1 SERIAL</td>
                <td>PROPELLER2 SERIAL</td>
                <td>SERIAL NUMBER</td>
                <td>TAKE OFF TIME</td>
                <td>TECH LOG NO</td>
                <td>TECHLOG DATE</td>
                <td>TECHLOG TYPE</td>
                <td>TO</td>
                <td>TOTAL FUEL</td>
              </tr>
            </thead>
            <tbody>
              {filteredData.length > 0 &&
                filteredData.map((item, index) => (
                  <tr key={index} className="for__more__data">
                    <td>
                      <p className=" ">{item["AIRCRAFT"] || "NA"}</p>
                    </td>
                    <td>
                      <p className=" ">{item["BLOCK TIME HOURS"] || "NA"}</p>
                    </td>
                    <td>
                      <p className=" ">{item["BLOCK TIME MINUTES"] || "NA"}</p>
                    </td>
                    <td>
                      <p className=" ">
                        {item["CHECK OFF"]
                          ? item["CHECK OFF"].slice(11, 19)
                          : "NA"}
                      </p>
                    </td>
                    <td>
                      <p className=" ">
                        {item["CHECK ON"]
                          ? item["CHECK ON"].slice(11, 19)
                          : "NA"}
                      </p>
                    </td>
                    <td>
                      <p className=" ">
                        {item["CREATED DATE"]
                          ? item["CREATED DATE"].slice(8, 10) +
                            "/" +
                            item["CREATED DATE"].slice(5, 7) +
                            "/" +
                            item["CREATED DATE"].slice(0, 4)
                          : "NA"}
                      </p>
                    </td>
                    <td>
                      <p className=" ">{item["ENGINE CYCLES"] || "0"}</p>
                    </td>
                    <td>
                      <p className=" ">{item["ENGINESERIAL"] || "NA"}</p>
                    </td>
                    <td>
                      <p className="  text-right">
                        {item["FLYING HRS"] || "NA"}
                      </p>
                    </td>
                    <td>
                      <p className=" ">{item["FROM"] || "NA"}</p>
                    </td>
                    <td>
                      <p className=" ">{item["FUEL LH"] || "NA"}</p>
                    </td>
                    <td>
                      <p className=" ">{item["FUEL RH"] || "NA"}</p>
                    </td>
                    <td>
                      <p className=" ">
                        {item["LANDING TIME"]
                          ? item["LANDING TIME"].slice(11, 19)
                          : "NA"}
                      </p>
                    </td>
                    <td>
                      <p className="  text-right">{item["LEGINDEX"] || "0"}</p>
                    </td>
                    <td>
                      <p className="  text-right">{item["MILES"] || "0"}</p>
                    </td>
                    <td>
                      <p className="  text-right">
                        {item["MILES/HOUR"] !== undefined &&
                        item["MILES/HOUR"] !== null
                          ? item["MILES/HOUR"].toFixed(2)
                          : "0"}
                      </p>
                    </td>
                    <td>
                      <p className=" ">{item["MINUTES"] || "0"}</p>
                    </td>
                    <td>
                      <p className=" ">{item["PERIOD"] || "0"}</p>
                    </td>
                    <td>
                      <p className=" ">{item["PILOT CODE"] || "NA"}</p>
                    </td>
                    <td>
                      <p className=" ">{item["PILOT NAME"] || "NA"}</p>
                    </td>
                    <td>
                      <p className=" ">{item["PROPELLER1SERIAL"] || "NA"}</p>
                    </td>
                    <td>
                      <p className=" ">{item["PROPELLER2SERIAL"] || "NA"}</p>
                    </td>
                    <td>
                      <p className="  text-right">
                        {item["SERIALNUMBER"] || "NA"}
                      </p>
                    </td>
                    <td>
                      <p className=" ">
                        {item["TAKE OFF TIME"]
                          ? item["TAKE OFF TIME"].slice(11, 19)
                          : "NA"}
                      </p>
                    </td>
                    <td>
                      <p className=" ">{item["TECH LOG NO"] || "NA"}</p>
                    </td>
                    <td>
                      <p className=" ">
                        {item["TECHLOG DATE"]
                          ? item["TECHLOG DATE"].slice(8, 10) +
                            "/" +
                            item["TECHLOG DATE"].slice(5, 7) +
                            "/" +
                            item["TECHLOG DATE"].slice(0, 4)
                          : "NA"}
                      </p>
                    </td>
                    <td>
                      <p className=" ">{item["TECHLOG TYPE"] || "NA"}</p>
                    </td>
                    <td>
                      <p className=" ">{item["TO"] || "NA"}</p>
                    </td>
                    <td>
                      <p className=" ">{item["TOTAL FUEL"] || "0"}</p>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default MYTAKEOFFSANDLANDINGS;
