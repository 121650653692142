import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "../CSS/FuelExpence.css";
import moment from "moment";
import axios, { Axios } from "axios";

import { BsSearch } from "react-icons/bs";
import Loading from "./Loading";
import Footer from "./Footer";
import { useSelector } from "react-redux";
import Navbar from "./Navbar";
const FuelExpence = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const BASEURL_GLOBAL = process.env.REACT_APP_BASEURL_GLOBAL;

  const authToken = window.sessionStorage.getItem("auth-token");
  const sclientSecret = window.sessionStorage.getItem("sclientSecret");
  const location = useLocation();
  const menu = location.state ? location.state.menu : null;
  const [supliers, setSupliers] = useState([]);
  const [techlogno, setTechlogno] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [quantity, setQuantity] = useState(0);
  const [rate, setRate] = useState(0);
  const userState = useSelector((state) => state.getUserState);
  const [rowCount, setRowCount] = useState(30);
  const [localamount, setLocalAmount] = useState(0);
  const navigate = useNavigate();
  const [fuelExpenceDetails, setfuelExpenceDetails] = useState({
    orderno: "",
    fueldate: "",
    aircraft: "",
    pilotcode: "",
    suppliercode: "",
    techlogno: 0,
    qty: 0,
    rate: 0,
    amount: 0,
    location: "",
    exchrate: 1,
    currency: "",
    recieptno: "",
  });
  const [latestNo, setLatestNo] = useState("");
  const [techLogno, setTechLogNumber] = useState("SELECT");
  const [supplier, setSupplier] = useState("SELECT");
  const [inpdisable, setInpDisable] = useState(true);
  const [err, setErr] = useState("");
  const ref = useRef();
  const [fuelRate, setFuelRate] = useState("");
  const [exchangerate, setExchangeRate] = useState(1);

  const [total, setTotal] = useState(0);
  const [routes, setRoutes] = useState([]);
  const [aircraft, setAircraft] = useState([]);
  // const [docno, setDocno] = useState(0);
  const [docno, setDocno] = useState("");
  const [pilot, setPilotslist] = useState([]);
  const [fuelpurchases, setFuelpurchases] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [searchterm, setsearchterm] = useState("");
  const [pilotName, setPilotName] = useState("SELECT");
  //  const loginState = useSelector((state) => state.getUserState);

  const userLoggedIN = userState?.user?.userCode;
  console.log(userLoggedIN, "userLoggedIN");

  const [myDataNext, setMyData] = useState("");
  function getintData() {
    try {
      if (!authToken) {
        navigate("/");
      } else {
        fuelExpenceDetails.fueldate = moment(new Date().toISOString()).format(
          "DD/MM/YYYY"
        );

        setIsLoading(true);
        axios
          // .get(`${BASEURL}/getfueldocno`, {
          .get(`${BASEURL_GLOBAL}/globalViewHandler?viewname=241`, {
            headers: {
              "auth-token": authToken,
              "session-token": sclientSecret,
            },
          })
          .then((response) => {
            setDocno(response.data[0].FUELORDERNO);
            setLatestNo(response.data[0].FUELORDERNO);
            setMyData(response.data[0].FUELORDERNO);
            fuelExpenceDetails.orderno = response.data[0].FUELORDERNO;
          })
          .catch((error) => {
            console.error("Error in getfueldocno:", error);
            // Handle error here
          });

        axios
          // .get(`${BASEURL}/getsuppliers`, {
          .get(`${BASEURL_GLOBAL}/globalViewHandler?viewname=32`, {
            headers: {
              "auth-token": authToken,
              "session-token": sclientSecret,
            },
          })
          .then((response) => {
            setSupliers(response.data);
          })
          .catch((error) => {
            console.error("Error in getsuppliers:", error);
            // Handle error here
          });

        axios
          // .get(`${BASEURL}/getRoutes`, {
          .get(`${BASEURL_GLOBAL}/globalViewHandler?viewname=312`, {
            headers: {
              "auth-token": authToken,
              "session-token": sclientSecret,
            },
          })
          .then((response) => {
            setRoutes(response.data);
          })
          .catch((error) => {
            console.error("Error in getRoutes:", error);
            // Handle error here
          });

        axios
          // .get(`${BASEURL}/getfuelpurchase`, {
          .get(`${BASEURL_GLOBAL}/globalViewHandler?viewname=359`, {
            headers: {
              "auth-token": authToken,
              "session-token": sclientSecret,
            },
          })
          .then((response) => {
            setFuelpurchases(response.data);
            setFilteredData(response.data);
          })
          .catch((error) => {
            console.error("Error in getfuelpurchase:", error);
            // Handle error here
          });

        axios
          // .get(`${BASEURL}/getircrafts`, {
          .get(`${BASEURL_GLOBAL}/globalViewHandler?viewname=298`, {
            headers: {
              "auth-token": authToken,
              "session-token": sclientSecret,
            },
          })
          .then((response) => {
            setAircraft(response.data);
          })
          .catch((error) => {
            console.error("Error in getircrafts:", error);
            // Handle error here
          });

        axios
          // .get(`${BASEURL}/getpilots`, {
          .get(`${BASEURL_GLOBAL}/globalViewHandler?viewname=501`, {
            headers: {
              "auth-token": authToken,
              "session-token": sclientSecret,
            },
          })
          .then((response) => {
            setPilotslist(response.data);
            setIsLoading(false);
          })
          .catch((error) => {
            console.error("Error in getpilots:", error);
            // Handle error here
          });
      }
    } catch (error) {
      console.error("An error occurred in getintData:", error);
      // Handle the error here
    }
  }

  var currenttime = moment().format("DD/MM/YYYY");
  useEffect(getintData, []);
  var curtimeone = moment().format("DD-MM-YYYY");

  function handleFilter() {
    const filteredArray = fuelpurchases.filter((item) => {
      const itemDate = new Date(item.FUELDATE); // Assuming each item has a date property

      if (fromDate && toDate) {
        return itemDate >= new Date(fromDate) && itemDate <= new Date(toDate);
      } else if (fromDate) {
        return itemDate >= new Date(fromDate);
      } else if (toDate) {
        return itemDate <= new Date(toDate);
      }

      return true;
    });

    setFilteredData(filteredArray);
  }

  function hanldeapprovedfilter(e) {
    const filteredArray = fuelpurchases.filter((item) => {
      if (e.target.value === "APPROVED") {
        return item.APPROVED === true;
      } else if (e.target.value === "CANCELLED") {
        return item.CANCELLED === true;
      }
      return true;
    });
    setFilteredData(filteredArray);
  }
  let currentDocumentNum = null;

  // Fetch old document details and place them
  async function getDocumentDetails(num) {
    try {
      setIsLoading(true);
      var doc = num;
      const response = await axios.post(
        `${BASEURL}/getFuelexpensebydoc`,
        {
          doc: doc,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sclientSecret,
          },
        }
      );

      if (response.data.message) {
        alert(response.data.message);
        window.location.reload();
      } else {
        setfuelExpenceDetails(response.data[0]);
        setSupplier(response.data[0].VENDNAME);
        setPilotName(response.data[0].PILOTNAME);
        setTechLogNumber(response.data[0].techlogno);
        setTotal(response.data[0].amount);
        setIsLoading(false);
        setDocno(response.data[0].orderno);
        setFuelRate(response.data[0].rate);
        setExchangeRate(response.data[0].exchrate);
        setLocalAmount(
          Number(response.data[0].exchrate) * Number(response.data[0].amount)
        );
      }
    } catch (error) {
      console.error("An error occurred in getDocumentDetails:", error);
      // Handle the error here
    }
  }

  //1st

  function handleFirstDocumentClick() {
    const firstDocumentNum = 1;
    getDocumentDetails(firstDocumentNum);
  }
  //last
  function handleLastDocumentClick() {
    window.location.reload();
  }

  //back

  function handleSubtractClick() {
    if (docno >= 1) {
      setDocno((prevDocno) => prevDocno - 1);
      getDocumentDetails(Number(docno) - 1);
    }
  }
  //next
  function handleAddtractClick() {
    // setDocno((prevDocno) => prevDocno + 1);
    // getDocumentDetails(docno + 1);
    if (latestNo === docno + 1) {
      window.location.reload();
    } else {
      getDocumentDetails(docno + 1);
    }
  }

  const renderList = (
    <div>
      <Navbar />
      <div className="content">
        <h2 className="">
          <div>
            <h3 style={{ textAlign: "center" }}> {menu || "FUEL EXPENSES"} </h3>
          </div>
          <Link className="link__defoult" to="/main">
            <button className="button__fuel_back">BACK</button>{" "}
          </Link>{" "}
        </h2>
        <aside className="for__icon__gt__Lt">
          <p
            className="Fuel__expense__redirect"
            onClick={handleFirstDocumentClick}
          >
            &lt;&lt;
          </p>
          <p className="Fuel__expense__redirect" onClick={handleSubtractClick}>
            &lt;
          </p>
          <p className="Fuel__expense__redirect" onClick={handleAddtractClick}>
            &gt;
          </p>
          <p
            className="Fuel__expense__redirect"
            onClick={handleLastDocumentClick}
          >
            &gt;&gt;
          </p>
        </aside>
        <main className="main__2__table">
          <table className="Fuel__expence__table">
            <tr>
              <td> RECIEPT NO:</td>
              <td>
                {" "}
                <input
                  onChange={(e) => {
                    fuelExpenceDetails.recieptno = e.target.value;
                  }}
                  defaultValue={fuelExpenceDetails.recieptno}
                  type="text"
                  name="orderno"
                />
              </td>
            </tr>
            <tr>
              <td>FUEL ORDER NO:</td>
              <td>
                {" "}
                <input
                  type="text"
                  name="orderno"
                  // onChange={(e) => {
                  //   fuelExpenceDetails.orderno = e.target.value;
                  // }}
                  defaultValue={fuelExpenceDetails.orderno}
                  disabled={true}
                />
              </td>
            </tr>

            <tr>
              <td>SUPPLIER NAME:</td>
              <td>
                {" "}
                <select
                  className="select__width1"
                  onChange={async (e) => {
                    try {
                      fuelExpenceDetails.suppliercode = e.target.value;
                      if (!fuelExpenceDetails.location) {
                        const response = await axios.post(
                          `${BASEURL}/getFuelRates/one`,
                          {
                            suppCode: e.target.value,
                            date: fuelExpenceDetails.fueldate,
                          },
                          // .get(
                          //   `${BASEURL_GLOBAL}/globalViewHandler?viewname=571&SUPPLIERCODE=${e.target.value}&FUELDATE=${fuelExpenceDetails.fueldate}`,
                          {
                            headers: {
                              "auth-token": authToken,
                              "session-token": sclientSecret,
                            },
                          }
                        );

                        if (response.data.recordset.length !== 0) {
                          setFuelRate(
                            Number(response.data.recordset[0].FUELRATE)
                          );
                          fuelExpenceDetails.exchrate = Number(
                            response.data.recordset[0].EXCHANGERATE
                          );
                          setExchangeRate(
                            Number(response.data.recordset[0].EXCHANGERATE)
                          );
                          fuelExpenceDetails.rate = Number(
                            response.data.recordset[0].FUELRATE
                          );
                          fuelExpenceDetails.currency =
                            response.data.recordset[0].CURRENCY;
                          if (fuelExpenceDetails.qty) {
                            setTotal(
                              Number(fuelExpenceDetails.qty) *
                                Number(response.data.recordset[0].FUELRATE)
                            );
                            setLocalAmount(
                              Number(fuelExpenceDetails.qty) *
                                Number(response.data.recordset[0].FUELRATE) *
                                Number(response.data.recordset[0].EXCHANGERATE)
                            );
                          }
                        } else {
                          setFuelRate("N/A");
                          fuelExpenceDetails.rate = 0;
                        }
                      } else if (fuelExpenceDetails.location) {
                        const response = await axios.post(
                          `${BASEURL}/getFuelRates/both`,
                          {
                            suppCode: e.target.value,
                            date: fuelExpenceDetails.fueldate,
                            location: fuelExpenceDetails.location,
                          },
                          {
                            headers: {
                              "auth-token": authToken,
                              "session-token": sclientSecret,
                            },
                          }
                        );

                        if (response.data.recordset.length !== 0) {
                          setFuelRate(response.data.recordset[0].FUELRATE);
                          fuelExpenceDetails.currency =
                            response.data.recordset[0].CURRENCY;
                          fuelExpenceDetails.rate =
                            response.data.recordset[0].FUELRATE;
                          fuelExpenceDetails.exchrate = Number(
                            response.data.recordset[0].EXCHANGERATE
                          );
                          setExchangeRate(
                            Number(response.data.recordset[0].EXCHANGERATE)
                          );
                        }
                      }
                    } catch (error) {
                      console.error("An error occurred:", error);
                      // Handle the error here
                    }
                  }}
                >
                  <option>{supplier}</option>
                  {supliers.map((data) => {
                    return (
                      <option value={data.VENDORCODE}>{data.VENDNAME}</option>
                    );
                  })}
                </select>
              </td>
            </tr>

            <tr>
              <td> LOCATION:</td>
              <td>
                <select
                  className="select__width1"
                  onChange={async (e) => {
                    try {
                      fuelExpenceDetails.location = e.target.value;
                      if (fuelExpenceDetails.suppliercode) {
                        const response = await axios.post(
                          `${BASEURL}/getFuelRates/both`,
                          {
                            suppCode: fuelExpenceDetails.suppliercode,
                            date: fuelExpenceDetails.fueldate,
                            location: e.target.value,
                          },
                          {
                            headers: {
                              "auth-token": authToken,
                              "session-token": sclientSecret,
                            },
                          }
                        );

                        if (response.data.recordset.length !== 0) {
                          setFuelRate(response.data.recordset[0].FUELRATE);
                          fuelExpenceDetails.currency =
                            response.data.recordset[0].CURRENCY;
                          fuelExpenceDetails.rate =
                            response.data.recordset[0].FUELRATE;
                          fuelExpenceDetails.exchrate = Number(
                            response.data.recordset[0].EXCHANGERATE
                          );
                          setExchangeRate(
                            Number(response.data.recordset[0].EXCHANGERATE)
                          );
                        }
                      } else {
                        const response = await axios.post(
                          `${BASEURL}/getFuelRates/one`,
                          {
                            suppCode: fuelExpenceDetails.suppliercode,
                            date: fuelExpenceDetails.fueldate,
                          },
                          {
                            headers: {
                              "auth-token": authToken,
                              "session-token": sclientSecret,
                            },
                          }
                        );

                        if (response.data.recordset.length !== 0) {
                          setFuelRate(response.data.recordset[0].FUELRATE);
                          fuelExpenceDetails.rate = Number(
                            response.data.recordset[0].FUELRATE
                          );
                          fuelExpenceDetails.currency =
                            response.data.recordset[0].CURRENCY;
                          setExchangeRate(
                            Number(response.data.recordset[0].EXCHANGERATE)
                          );
                          fuelExpenceDetails.exchrate = Number(
                            response.data.recordset[0].EXCHANGERATE
                          );
                        } else {
                          setFuelRate("N/A");
                          fuelExpenceDetails.rate = 0;
                        }
                      }
                    } catch (error) {
                      console.error("An error occurred:", error);
                      // Handle the error here
                    }
                  }}
                >
                  <option>{fuelExpenceDetails.location}</option>
                  {routes.map((data) => {
                    return <option>{data.CITYCODE}</option>;
                  })}
                </select>
              </td>
            </tr>
            <tr>
              <td>AIRCRAFT:</td>
              <td>
                <select
                  className="select__width1"
                  onChange={(e) => {
                    try {
                      fuelExpenceDetails.aircraft = e.target.value;
                      axios
                        .post(
                          `${BASEURL}/gettechlogfromaircraft`,
                          {
                            flight: e.target.value,
                          },
                          {
                            headers: {
                              "auth-token": authToken,
                              "session-token": sclientSecret,
                            },
                          }
                        )
                        .then((response) => {
                          if (response.data.message) {
                            alert(response.data.message);
                          } else if (response.data.recordset.length === 0) {
                            //
                          } else {
                            setTechlogno(response.data.recordset);
                          }
                        });
                    } catch (error) {
                      console.error("An error occurred:", error);
                      // Handle the error here
                    }
                  }}
                >
                  <option className="select__width">
                    {fuelExpenceDetails.aircraft}
                  </option>
                  {aircraft.map((data) => {
                    return <option>{data.AIRCRAFTREG}</option>;
                  })}
                </select>
              </td>
            </tr>

            <tr>
              <td>PILOT:</td>
              <td>
                {" "}
                {/* <input
            type="text"
            name="pilotcode"
            onChange={(e) => {
              fuelExpenceDetails.pilotcode = e.target.value;
            }}
          /> */}
                <select
                  className="select__width1"
                  onChange={(e) => {
                    fuelExpenceDetails.pilotcode = e.target.value;
                  }}
                >
                  <option className="select__width1">{pilotName}</option>
                  {pilot.map((data) => {
                    return (
                      <option value={data.PILOTCODE}>{data.PILOTNAME}</option>
                    );
                  })}
                </select>
              </td>
            </tr>
            <tr>
              {" "}
              <td>TECHLOG NO:</td>
              <td>
                <select
                  className="select__width1"
                  onChange={(e) => {
                    try {
                      fuelExpenceDetails.techlogno = e.target.value;
                      var d = e.target.value;
                      axios
                        .post(
                          `${BASEURL}/getHeaderDetails`,
                          {
                            docNo: d,
                          },
                          {
                            headers: {
                              "auth-token": authToken,
                              "session-token": sclientSecret,
                            },
                          }
                        )
                        .then((response) => {
                          fuelExpenceDetails.pilotcode =
                            response.data.recordset[0].pilotcode;
                          axios
                            .post(
                              `${BASEURL}/getpilotname`,
                              {
                                code: response.data.recordset[0].pilotcode,
                              },
                              {
                                headers: {
                                  "auth-token": authToken,
                                  "session-token": sclientSecret,
                                },
                              }
                            )
                            .then((response) => {
                              setPilotName(
                                response.data.recordset[0].PILOTNAME
                              );
                            });
                        });
                    } catch (error) {
                      console.error("An error occurred:", error);
                      // Handle the error here
                    }
                  }}
                >
                  <option className="select__width1">{techLogno}</option>
                  {techlogno.map((data) => {
                    return <option>{data.OT_TECH_LOG_DOC_NO}</option>;
                  })}
                </select>
              </td>
            </tr>

            <tr>
              {/* <td>RATE:</td>
              <td>
                {" "}
                <input
                  type="text"
                  name="rate"
                  id="rate"
                  placeholder={fuelExpenceDetails.rate}
                  onChange={(e) => {
                    setRate(Number(e.target.value));
                    setTotal(quantity * Number(e.target.value));
                    fuelExpenceDetails.rate = e.target.value;
                    fuelExpenceDetails.amount =
                      Number(e.target.value) * Number(fuelExpenceDetails.qty);
                   
                  }}
                />
              </td> */}
            </tr>

            <tr></tr>
          </table>
          <table className="Fuel__expence__table">
            <tr>
              <td> RECIEPT DATE:</td>
              <td>
                <input
                  name="fueldate"
                  type="date"
                  style={{ width: "130px" }}
                  onChange={async (e) => {
                    try {
                      // fuelExpenceDetails.fueldate = moment(e.target.value).format(
                      //   "DD/MM/YYYY"
                      // );
                      const y = e.target.value.slice(0, 4);
                      const m = e.target.value.slice(5, 7);
                      const d = e.target.value.slice(8, 10);
                      const f = `${d}/${m}/${y}`;
                      fuelExpenceDetails.fueldate = f;

                      if (
                        fuelExpenceDetails.suppliercode &&
                        fuelExpenceDetails.location
                      ) {
                        const response = await axios.post(
                          `${BASEURL}/getFuelRates/both`,
                          {
                            suppCode: fuelExpenceDetails.suppliercode,
                            date: f,
                            location: fuelExpenceDetails.location,
                          },
                          {
                            headers: {
                              "auth-token": authToken,
                              "session-token": sclientSecret,
                            },
                          }
                        );

                        if (response.data.recordset.length !== 0) {
                          setFuelRate(
                            Number(response.data.recordset[0].FUELRATE)
                          );
                          fuelExpenceDetails.exchrate = Number(
                            response.data.recordset[0].EXCHANGERATE
                          );
                          setExchangeRate(
                            Number(response.data.recordset[0].EXCHANGERATE)
                          );
                          fuelExpenceDetails.rate = Number(
                            response.data.recordset[0].FUELRATE
                          );
                          fuelExpenceDetails.currency =
                            response.data.recordset[0].CURRENCY;
                          if (fuelExpenceDetails.qty) {
                            setTotal(
                              Number(fuelExpenceDetails.qty) *
                                Number(response.data.recordset[0].FUELRATE)
                            );
                            setLocalAmount(
                              Number(fuelExpenceDetails.qty) *
                                Number(response.data.recordset[0].FUELRATE) *
                                Number(response.data.recordset[0].EXCHANGERATE)
                            );
                          }
                        } else {
                          setFuelRate("N/A");
                          fuelExpenceDetails.rate = 0;
                        }
                      } else if (
                        fuelExpenceDetails.suppliercode &&
                        !fuelExpenceDetails.location
                      ) {
                        const response = await axios.post(
                          `${BASEURL}/getFuelRates/one`,
                          {
                            suppCode: fuelExpenceDetails.suppliercode,
                            date: f,
                          },
                          {
                            headers: {
                              "auth-token": authToken,
                              "session-token": sclientSecret,
                            },
                          }
                        );

                        if (response.data.recordset.length !== 0) {
                          setFuelRate(
                            Number(response.data.recordset[0].FUELRATE)
                          );
                          fuelExpenceDetails.exchrate = Number(
                            response.data.recordset[0].EXCHANGERATE
                          );
                          setExchangeRate(
                            Number(response.data.recordset[0].EXCHANGERATE)
                          );
                          fuelExpenceDetails.rate = Number(
                            response.data.recordset[0].FUELRATE
                          );
                          fuelExpenceDetails.currency =
                            response.data.recordset[0].CURRENCY;
                          if (fuelExpenceDetails.qty) {
                            setTotal(
                              Number(fuelExpenceDetails.qty) *
                                Number(response.data.recordset[0].FUELRATE)
                            );
                            setLocalAmount(
                              Number(fuelExpenceDetails.qty) *
                                Number(response.data.recordset[0].FUELRATE) *
                                Number(response.data.recordset[0].EXCHANGERATE)
                            );
                          }
                        } else {
                          setFuelRate("N/A");
                          fuelExpenceDetails.rate = 0;
                        }
                      }
                    } catch (error) {
                      console.error("An error occurred:", error);
                      // Handle the error here
                    }
                  }}
                  defaultValue={fuelExpenceDetails.fueldate.split("T")[0]}
                  ref={ref}
                />
              </td>
            </tr>
            <tr>
              <td>FUEL RATE:</td>

              <td>
                <input
                  name="fuelrate"
                  type="number"
                  disabled="true"
                  value={fuelRate}
                  ref={ref}
                />
              </td>
            </tr>
            <tr>
              <td>EXCHANGE RATE:</td>
              <td>
                {" "}
                <label style={{ color: "red" }}>{err}</label>
                <input
                  type="number"
                  name="rate"
                  disabled
                  value={Number(exchangerate)}
                  // onChange={(e) => {
                  //   if (
                  //     fuelExpenceDetails.currency === "USD" &&
                  //     e.target.value <= 1
                  //   ) {
                  //     setErr("Must be greater than 1");
                  //   } else {
                  //     fuelExpenceDetails.exchrate = e.target.value;
                  //     setErr("");
                  //     setExchangeRate(e.target.value);
                  //     setLocalAmount(Number(total) * Number(e.target.value));
                  //   }
                  // }}
                />
              </td>
            </tr>

            <tr>
              <td> CURRENCY:</td>
              <td>
                <input
                  disabled
                  type="text"
                  name="amount"
                  value={fuelExpenceDetails.currency}
                />
              </td>
            </tr>
            <tr>
              <td> QTY:</td>
              <td>
                {" "}
                <input
                  defaultValue={fuelExpenceDetails.qty}
                  type="number"
                  name="qty"
                  onChange={(e) => {
                    fuelExpenceDetails.qty = e.target.value;
                    setQuantity(Number(e.target.value));
                    setLocalAmount(
                      Number(fuelRate) *
                        Number(e.target.value) *
                        Number(fuelExpenceDetails.exchrate)
                    );
                    if (fuelExpenceDetails.rate !== 0) {
                      setTotal(Number(fuelRate) * Number(e.target.value));
                      fuelExpenceDetails.amount =
                        Number(fuelExpenceDetails.rate) *
                        Number(e.target.value);
                    }
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>TOTAL AMOUNT:</td>
              <td>
                {" "}
                <input
                  disabled
                  type="text"
                  name="amount"
                  value={Number(total.toFixed(2))}
                />
              </td>
            </tr>
            <tr>
              <td>
                TOTAL AMOUNT:
                <p style={{ color: "red", fontSize: "14px" }}>
                  (LOCAL CURRENCY)
                </p>
              </td>
              <td>
                {" "}
                <input disabled type="number" value={localamount.toFixed(2)} />
              </td>
            </tr>
          </table>
        </main>
        {/* new table here */}
        <table className="Fuel__expence__table">
          <tr>
            <aside className="for__mobile_button">
              <div>
                <th>
                  <button
                    className="button__fuel"
                    onClick={async () => {
                      try {
                        // fuelExpenceDetails.fueldate = currenttime;
                        fuelExpenceDetails.amount = total;

                        if (fuelExpenceDetails.aircraft === "SELECT" || "") {
                          alert("Select aircraft");
                        } else if (
                          fuelExpenceDetails.currency === "USD" &&
                          fuelExpenceDetails.exchrate <= 1
                        ) {
                          alert(
                            "Exchange rate must be greater than 1 in case of USD"
                          );
                        } else {
                          if (fuelExpenceDetails.rate !== 0) {
                            if (!fuelExpenceDetails.orderno) {
                              alert("Order no is required!");
                              return;
                            }
                            const response = await axios.post(
                              `${BASEURL}/approveinsertfuelpurchase`,
                              fuelExpenceDetails,
                              {
                                headers: {
                                  "auth-token": authToken,
                                  "session-token": sclientSecret,
                                },
                              }
                            );
                            alert(response.data.message);
                            window.location.reload();
                          } else {
                            // Handle the case where rate is 0
                          }
                        }
                      } catch (error) {
                        console.error("An error occurred:", error);
                        // Handle the error here
                      }
                    }}
                  >
                    SAVE
                  </button>
                </th>
              </div>
              {/* <div>
                <th>
                  <button
                    onClick={() => {
                      fuelExpenceDetails.orderno = docno;
                      axios
                        .post(
                          `${BASEURL}/approveinsertfuelpurchase`,
                          fuelExpenceDetails,
                          {
                           headers: {
          "auth-token": authToken,
          "session-token": sclientSecret,
        },
                          }
                        )
                        .then((response) => {
                          alert(response.data.message, window.location.reload);
                        });
                    }}
                    className="button__fuel"
                  >
                    APPROVE
                  </button>{" "}
                </th>
              </div> */}
              <div>
                <th>
                  <button
                    onClick={async () => {
                      try {
                        const payload = {
                          ORDERNO: fuelExpenceDetails.orderno || docno,
                          FUELDATE: new Date(
                            fuelExpenceDetails.fueldate
                          ).toISOString(), // Ensures proper date format
                          AIRCRAFT: fuelExpenceDetails.aircraft,
                          PILOTCODE: fuelExpenceDetails.pilotcode,
                          SUPPLIERCODE: fuelExpenceDetails.suppliercode,
                          TECHLOGNO: fuelExpenceDetails.techlogno,
                          QTY: fuelExpenceDetails.qty,
                          RATE: fuelExpenceDetails.rate,
                          CURRENCY: fuelExpenceDetails.currency,
                          EXCHRATE: fuelExpenceDetails.exchrate,
                          RECEIPTNO: fuelExpenceDetails.recieptno,
                          AMOUNT: fuelExpenceDetails.amount,
                          LOCATION: fuelExpenceDetails.location,
                          APPROVED: 0, // Assuming default as 0 for unapproved
                          APPROVEDBY: "", // Placeholder, set appropriately if available
                          CANCELLED: 1, // Assuming default as cancelled
                          CANCELLEDBY: userLoggedIN || "USER", // Replace `userLoggedIN` with the current user
                          // SUCCESS_STATUS: "",
                          // ERROR_STATUS: "",
                        };

                        const response = await axios.post(
                          `${BASEURL}/cancelinsertfuelpurchase`,
                          // `${BASEURL_GLOBAL}/globalSpHandler?spname=563 `,
                          payload,
                          {
                            headers: {
                              "auth-token": authToken,
                              "session-token": sclientSecret,
                            },
                          }
                        );

                        alert(response.data.message);
                        window.location.reload();
                      } catch (error) {
                        console.error("An error occurred:", error);
                        // Handle the error here
                      }
                    }}
                    className="button__fuel"
                  >
                    CANCEL
                  </button>
                </th>
              </div>
              <div>
                <th>
                  {" "}
                  <button
                    hidden={userState?.user?.userType === true ? false : true}
                    onClick={async () => {
                      try {
                        fuelExpenceDetails.orderno = docno;
                        const response = await axios.post(
                          // `${BASEURL}/deletefuelpurchase`,
                          `${BASEURL_GLOBAL}/globalSpHandler?spname=83 `,
                          {
                            ORDERNO: fuelExpenceDetails.orderno,
                            SUCCESS_STATUS: "",
                          },
                          {
                            headers: {
                              "auth-token": authToken,
                              "session-token": sclientSecret,
                            },
                          }
                        );
                        alert(response.data.message);
                        window.location.reload();
                      } catch (error) {
                        console.error("An error occurred:", error);
                        // Handle the error here
                      }
                    }}
                    className="button__fuel"
                  >
                    DELETE
                  </button>{" "}
                </th>
              </div>
            </aside>
          </tr>
          <tr>
            <td colSpan={3}>
              <p className="List__of__fuel">LIST OF FUEL PURCHASE:</p>
            </td>
          </tr>
          <tr>
            <aside className="for__mobile">
              <div>
                <td>
                  START DATE:{" "}
                  <input
                    style={{ width: "130px" }}
                    type="date"
                    onChange={(e) => setFromDate(e.target.value)}
                  />
                </td>
              </div>

              <div>
                <td>
                  END DATE:{" "}
                  <input
                    style={{ width: "130px" }}
                    type="date"
                    onChange={(e) => setToDate(e.target.value)}
                  />
                </td>
              </div>
              <div>
                <td>
                  <select
                    onChange={(e) => {
                      hanldeapprovedfilter(e);
                    }}
                    className="select__width__approvrd"
                  >
                    <option value="ALL" className="select__width">
                      ALL
                    </option>
                    <option value="APPROVED" className="select__width">
                      APPROVED
                    </option>
                    <option value="CANCELLED" className="select__width">
                      CANCELLED
                    </option>
                  </select>
                </td>
              </div>
              <div>
                <td>
                  <input
                    onChange={(e) => {
                      setsearchterm(e.target.value);
                    }}
                    className="date__fuel search__width"
                    type="TEXT"
                    placeholder="SEARCH"
                  />
                  <span className="span__icon__fuel__Expense">
                    {" "}
                    <BsSearch />
                  </span>
                </td>
              </div>
              <div>
                <td>
                  {" "}
                  <button className="button__fuel" onClick={handleFilter}>
                    FILTER
                  </button>
                </td>
              </div>
            </aside>
          </tr>
          <tr>
            <td>FILTERS:</td>
          </tr>
        </table>
        <section className="overflow__control">
          <table className="filter__table">
            <tr className="first__tr__filter">
              <td style={{ width: "50px" }}>
                <p style={{ textAlign: "left", width: "80px" }}>DATE</p>
              </td>
              <td>
                <p style={{ textAlign: "right" }}> FUEL ORDER NO</p>{" "}
              </td>
              <td style={{ textAlign: "right", paddingRight: "10px" }}>
                <p>RECIEPT NO</p>
              </td>
              <td>
                <p style={{ textAlign: "left", paddingLeft: "15px" }}>
                  SUPPLY CODE
                </p>
              </td>

              <td>
                <p style={{ textAlign: "left" }}>SUPPLIER NAME</p>
              </td>

              <td>
                <p style={{ textAlign: "left", paddingLeft: "10px" }}>
                  LOCATION
                </p>
              </td>

              <td>
                <p style={{ textAlign: "left" }}> AIRCRAFT</p>
              </td>
              <td>
                <p>TECHLOG NO</p>
              </td>
              <td style={{ textAlign: "right", paddingRight: "10px" }}>RATE</td>
              <td style={{ textAlign: "right", paddingRight: "10px" }}>QTY</td>
              <td style={{ textAlign: "right", paddingRight: "10px" }}>
                AMOUNT
              </td>
              {/* <td>
                <p style={{ textAlign: "left" }}>APPROVED</p>
              </td>
              <td>
                <p style={{ textAlign: "left" }}>CANCELLED</p>
              </td> */}
              <td>
                <p>ACTION</p>
              </td>
            </tr>
            {/* else if (data.RECIEPTNO !== "" || data.RECIEPTNO !== null) {
                  if (
                    data.RECIEPTNO.includes(searchterm.toString().toLowerCase())
                  ) {
                    return data;
                  }
                } */}
            {filteredData
              .filter((data) => {
                if (searchterm === null || searchterm === undefined) {
                  return true; // Return all data if searchterm is null or undefined
                } else if (
                  data.LOCATION &&
                  data.LOCATION.toString()
                    .toLowerCase()
                    .includes(searchterm.toString().toLowerCase())
                ) {
                  return true;
                } else if (
                  data.ORDERNO &&
                  data.ORDERNO.toString()
                    .toLowerCase()
                    .includes(searchterm.toString().toLowerCase())
                ) {
                  return true;
                } else if (
                  data.SUPPLIERCODE &&
                  data.SUPPLIERCODE.toString()
                    .toLowerCase()
                    .includes(searchterm.toString().toLowerCase())
                ) {
                  return true;
                } else if (
                  data.VENDNAME &&
                  data.VENDNAME.toString()
                    .toLowerCase()
                    .includes(searchterm.toString().toLowerCase())
                ) {
                  return true;
                } else if (
                  data.TECHLOGNO &&
                  data.TECHLOGNO.toString()
                    .toLowerCase()
                    .includes(searchterm.toString().toLowerCase())
                ) {
                  return true;
                } else if (
                  data.AIRCRAFT &&
                  data.AIRCRAFT.toString()
                    .toLowerCase()
                    .includes(searchterm.toString().toLowerCase())
                ) {
                  return true;
                } else if (
                  data.RECIEPTNO &&
                  data.RECIEPTNO.toString()
                    .toLowerCase()
                    .includes(searchterm.toString().toLowerCase())
                ) {
                  return true;
                }
                return false; // Return false if the searchterm doesn't match any criteria
              })
              .slice(0, rowCount)
              .map((data) => {
                return (
                  <tr>
                    <td
                      className="date__fuel__filter"
                      style={{ textAlign: "left", width: "100px" }}
                    >
                      {data.FUELDATE
                        ? data.FUELDATE.substr(0, 10)
                            .split("-")
                            .reverse()
                            .join("-")
                        : "N/A"}
                    </td>
                    <td
                      className="number__align"
                      style={{ width: "85px", paddingRight: "10px" }}
                    >
                      {data.ORDERNO === 0 ? "NA" : data.ORDERNO || "NA"}
                    </td>
                    <td
                      className="number__align"
                      style={{ width: "70px", paddingRight: "10px" }}
                    >
                      {" "}
                      {data.RECIEPTNO === 0 ? "NA" : data.RECIEPTNO || "NA"}
                    </td>
                    <td
                      style={{ textAlign: "left", paddingLeft: "20px" }}
                      className="number__align"
                    >
                      {data.SUPPLIERCODE}
                    </td>
                    <td
                      // style={{ textAlign: "left" }}
                      className="supplier_name__table"
                      style={{ textAlign: "left" }}
                    >
                      {data.VENDNAME}
                    </td>
                    <td className="" style={{ textAlign: "left" }}>
                      {data.LOCATION}
                    </td>
                    <td
                      className="location_aircraft"
                      style={{ textAlign: "left" }}
                    >
                      {data.AIRCRAFT}
                    </td>
                    <td className="number__align" style={{ width: "75px" }}>
                      {data.TECHLOGNO === 0 ? "NA" : data.TECHLOGNO}
                    </td>
                    {/* <td>{data.CURRENCY}</td>
                    <td>{data.EXCHRATE}</td> */}
                    <td style={{ textAlign: "right", paddingRight: "10px" }}>
                      {data.RATE}
                    </td>
                    <td style={{ textAlign: "right", paddingRight: "10px" }}>
                      {data.QTY}
                    </td>
                    <td style={{ textAlign: "right", paddingRight: "10px" }}>
                      {data.AMOUNT.toLocaleString()}
                    </td>
                    {/* <td style={{ textAlign: "left" }}>
                      {data.APPROVED === true ? "APPROVED" : "NOT APPROVED"}
                    </td>
                    <td style={{ textAlign: "left" }}>
                      {data.CANCELLED === true ? "CANCELLED" : "NOT CANCELLED"}
                    </td> */}
                    <td style={{ textAlign: "right", width: "50px" }}>
                      <button
                        className="view__btn"
                        onClick={(e) => {
                          getDocumentDetails(data.ORDERNO);
                        }}
                      >
                        View
                      </button>
                    </td>
                  </tr>
                );
              })}
          </table>
        </section>
        <div className="show__more">
          <button
            className="show__btn"
            onClick={() => {
              var l = filteredData.length;
              if (rowCount + 15 > l) {
                setRowCount(l);
              } else {
                setRowCount(rowCount + 15);
              }
            }}
          >
            SHOW MORE
          </button>
          <button
            className="show__btn"
            onClick={() => {
              var l = filteredData.length;
              if (rowCount - 15 <= 30) {
                setRowCount(30);
              } else {
                setRowCount(rowCount - 15);
              }
            }}
          >
            SHOW LESS
          </button>
        </div>
      </div>
      <Footer />
    </div>
  );

  return <>{isLoading ? <Loading /> : renderList}</>;
};

export default FuelExpence;
