import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useSelector } from "react-redux";
import axios from "axios";
import AllPagesNavbar from "./Navbar";
import Loading from "./Loading";
import { BiSolidEditAlt } from "react-icons/bi";
import { useLocation } from "react-router-dom";
import "./../CSS/EngineMaster.css";
import jsPDF from "jspdf";
import ExcelJS from "exceljs";
import "jspdf-autotable"; // Importing the AutoTable plugin
import { BsFileEarmarkPdf } from "react-icons/bs";
import { RiFileExcel2Line } from "react-icons/ri";
function TrainingType() {
  const location = useLocation();
  const menu = location.state ? location.state.menu : null;
  const BASEURL = process.env.REACT_APP_BASEURL;
  const BASEURL_GLOBAL = process.env.REACT_APP_BASEURL_GLOBAL;

  const authToken = window.sessionStorage.getItem("auth-token");
  const sclientSecret = window.sessionStorage.getItem("sclientSecret");
  const state = useSelector((state) => state?.loginreducer);
  const date = new Date().toISOString();
  const currentDate = new Date();
  const startOfYear = new Date(currentDate.getFullYear(), 0, 1);
  const localStartOfYear = new Date(
    startOfYear.getTime() - startOfYear.getTimezoneOffset() * 60000
  );
  const [startdate, setStartdate] = useState("");
  const [enddate, setEnddate] = useState("");
  const userState = useSelector((state) => state.getUserState);
  const userLoggeduserCode = userState.user.userCode;
  console.log(userLoggeduserCode);
  const [TrainingType, setTrainingType] = useState("");
  const [search, setSearch] = useState("");

  const [isloading, setisloading] = useState(false);

  const [TrainingDays, setTrainingDays] = useState(0);
  const [ListofTrainingType, setListofTrainingType] = useState([]);
  useEffect(() => {
    FetchDetails();
  }, []);
  function FetchDetails() {
    setisloading(true);
    axios
      // .get(`${BASEURL}/getTrainingTypes`, {
      .get(`${BASEURL_GLOBAL}/globalViewHandler?viewname=542`, {
        headers: {
          "auth-token": authToken,
          "session-token": sclientSecret,
        },
      })
      .then((res) => {
        console.log(res.data);
        setisloading(false);
        setListofTrainingType(res?.data);
      })
      .catch((err) => {
        setisloading(false);

        console.log("Error while fetching /getEngines List", err);
      });
  }
  const filteredData = ListofTrainingType.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);
    const sDate = new Date(item["TRAININGCREATEDDATE"]?.slice(0, 10));
    const eDate = new Date(item["TRAININGCREATEDDATE"]?.slice(0, 10));

    const textMatch = searchData.every((term) => {
      return [
        "TRAININGTYPE",
        "TRAININGDAYS",
        "TRAININGCREATEDDATE",
        "TRAININGCREATEDBY",
      ].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });
    const dateMatch =
      (!startdate || sDate >= new Date(startdate)) &&
      (!enddate || eDate <= new Date(enddate));

    return textMatch && dateMatch;
  });

  const HandleSave = async () => {
    if (!TrainingType) {
      alert("Please Provide Training Type");
      return;
    }
    if (!TrainingDays) {
      alert("Please Provide Training Days");
      return;
    }

    const confirmEdit = window.confirm("Are you sure you want to Save?");
    if (confirmEdit) {
      setisloading(true);

      try {
        const LICENSEResponse = await axios.post(
          // `${BASEURL}/insertTrainingType`,
          `${BASEURL_GLOBAL}/globalSpHandler?spname=23 `,

          {
            TRAININGTYPE: TrainingType,
            TRAININGDAYS: Number(TrainingDays),
            TRAININGCREATEDBY: userLoggeduserCode,
            SUCCESS_STATUS: "",
            ERROR_STATUS: "",
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sclientSecret,
            },
          }
        );
        alert(LICENSEResponse.data.message);
        window.location.reload();
        setisloading(false);
      } catch (error) {
        setisloading(false);
        alert(error.message);
      }
    }
  };

  function handleEdit(item) {
    setTrainingType(item.TRAININGTYPE);
    setTrainingDays(item.TRAININGDAYS);
  }

  // Add this function to your TrainingType component
  const ExcelTrainingData = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("TRAINING TYPES REPORT");

    const columnWidths = [25, 25, 30, 30];

    // Set column widths for the worksheet
    columnWidths.forEach((width, index) => {
      worksheet.getColumn(index + 1).width = width;
    });

    // Add headers
    const headerRow = worksheet.addRow([
      "TRAINING TYPE",
      "TRAINING DAYS",
      "CREATED DATE",
      "CREATED BY",
    ]);
    headerRow.eachCell((cell) => {
      cell.font = { bold: true };
    });

    // Add rows from the filteredData
    filteredData.forEach((item) => {
      const rowData = [
        item.TRAININGTYPE || "NA",
        item.TRAININGDAYS || "NA",
        item.TRAININGCREATEDDATE
          ? `${item.TRAININGCREATEDDATE.slice(
              8,
              10
            )}/${item.TRAININGCREATEDDATE.slice(
              5,
              7
            )}/${item.TRAININGCREATEDDATE.slice(0, 4)}`
          : "NA",
        item.TRAININGCREATEDBY || "NA",
      ];

      worksheet.addRow(rowData);
    });

    // Create a blob from the workbook and trigger download
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "TRAINING_TYPES_REPORT.xlsx";
      link.click();
    });
  };

  const handleGeneratePDF = () => {
    const doc = new jsPDF();

    doc.setFontSize(16);
    doc.text("Training Types List", 14, 20); // Title of the PDF

    const tableColumn = [
      { title: "TRAINING TYPE", dataKey: "trainingType" },
      {
        title: "TRAINING DAYS",
        dataKey: "trainingDays",
      },
      {
        title: "CREATED DATE",
        dataKey: "createdDate",
      },
      {
        title: "CREATED BY",
        dataKey: "createdBy",
      },
    ];
    const tableRows = [];

    filteredData.forEach((item) => {
      const trainingData = [
        item.TRAININGTYPE || "NA",
        item.TRAININGDAYS || "NA",
        item.TRAININGCREATEDDATE
          ? `${item.TRAININGCREATEDDATE.slice(
              8,
              10
            )}/${item.TRAININGCREATEDDATE.slice(
              5,
              7
            )}/${item.TRAININGCREATEDDATE.slice(0, 4)}`
          : "NA",
        item.TRAININGCREATEDBY || "NA",
      ];
      tableRows.push(trainingData);
    });

    doc?.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 30,
      showHead: "firstPage",
      margin: { top: 10, bottom: 10, left: 5, right: 5 }, // Reduce margins
      theme: "grid", // Keeps table compact
      styles: {
        cellPadding: 3,
        fontSize: 10,
        overflow: "linebreak",
        lineWidth: 0.1,
        valign: "middle",
      },
      columnStyles: {
        trainingType: { halign: "left" }, // Left align for the training type
        trainingDays: { halign: "right" }, // Right align for training days
        createdDate: { halign: "right" }, // Right align for created date
        createdBy: { halign: "right" }, // Right align for created by
      },
    });

    doc.save("TrainingTypes.pdf");
  };
  const scrollToSection = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  if (isloading) {
    return <Loading />;
  }
  return (
    <div className="bg-gray-50 min-h-[100vh] newpages__input">
      <AllPagesNavbar />
      <header className="header__of__main lg:flex justify-between items-end px-3">
        <h1 className="header__of__page">{menu || "Engine Master"}</h1>
        <div className="lg:flex items-center gap-5 my-1">
          {" "}
          <button
            onClick={() => {
              window.location.reload();
            }}
            className="border-l-indigo-900 rounded mx-2 text-white"
          >
            New
          </button>
          <button
            onClick={async () => {
              HandleSave();
            }}
            className="border-l-indigo-900 rounded mx-2 text-white"
          >
            Save
          </button>
          <div
            onClick={ExcelTrainingData}
            className="border-l-indigo-900 rounded mx-2 text-blue-950 cursor-pointer"
          >
            <RiFileExcel2Line size={35} />{" "}
          </div>
          <div
            onClick={handleGeneratePDF}
            className="border-l-indigo-900 rounded mx-2 text-blue-950 cursor-pointer"
          >
            <BsFileEarmarkPdf size={35} />
          </div>
        </div>
      </header>
      <aside className="m-5">
        <section className="lg:flex  gap-10">
          <div>
            <p>TRAINING TYPE</p>
            <input
              type="text"
              className="h-9 px-1 lg:w-[400px] w-[100%]"
              value={TrainingType}
              onChange={(e) => setTrainingType(e.target.value)}
            />
          </div>
          <div>
            <p>TRAINING DAYS</p>
            <input
              type="number"
              className="h-9 px-1"
              value={TrainingDays}
              onChange={(e) => setTrainingDays(e.target.value)}
            />
          </div>
        </section>
      </aside>
      {isloading ? (
        <Loading />
      ) : (
        <div className="z-10 lg:flex  flex-col w-full">
          <div>
            <div className="lg:flex mx-6">
              <p className="w-[70px]">Search:</p>
              <input
                className="w-[300px] h-7 border border-black p-2 rounded-md focus:outline-none focus:border-blue-500"
                type="text"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
              <div className="lg:flex items-center gap-3 lg:mx-4">
                <label className=" text-gray-700">From Date</label>
                <input
                  type="date"
                  onChange={(e) => {
                    setStartdate(e.target.value);
                  }}
                  value={startdate.split("T")[0]}
                  placeholder="From Date"
                />
              </div>
              <div className="lg:flex items-center gap-3">
                <label className=" text-gray-700">End Date</label>
                <input
                  type="date"
                  onChange={(e) => {
                    setEnddate(e.target.value);
                  }}
                  value={enddate.split("T")[0]}
                  placeholder="To Date"
                />
              </div>
            </div>
            <div className="overflow-y-auto h-[500px] px-4">
              <table className="custom-table  overflow__y__ relative text-[12px] ">
                <thead
                  className="header-cell sticky top-[0px] left-[10px]"
                  style={{ zIndex: 50 }}
                >
                  <tr>
                    <td className="">
                      <p>TRAINING TYPE</p>
                    </td>
                    <td className="text-right">TRAINING DAYS </td>

                    <td className="">TRAINING CREATED DATE & TIME</td>
                    <td>TRAINING CREATED BY</td>
                    <td className="w-[120px]">EDIT</td>
                  </tr>
                </thead>
                <tbody>
                  {filteredData.length > 0 &&
                    filteredData.map((item, index) => (
                      <tr
                        key={index}
                        style={{
                          textTransform: "uppercase",
                          cursor: "pointer",
                        }}
                      >
                        <td className="  ">
                          {item.TRAININGTYPE ? item.TRAININGTYPE : "NA"}
                        </td>
                        <td className=" text-right">
                          {item.TRAININGDAYS ? item.TRAININGDAYS : "NA"}
                        </td>

                        <td className="">
                          {item.TRAININGCREATEDDATE
                            ? item.TRAININGCREATEDDATE.slice(8, 10) +
                              "/" +
                              item.TRAININGCREATEDDATE.slice(5, 7) +
                              "/" +
                              item.TRAININGCREATEDDATE.slice(0, 4) +
                              " " +
                              item.TRAININGCREATEDDATE.slice(11, 19)
                            : "NA"}
                        </td>
                        <td className="">
                          {item.TRAININGCREATEDBY
                            ? item.TRAININGCREATEDBY
                            : "NA"}
                        </td>

                        <td
                          className="w-[100px]"
                          style={{
                            cursor: "pointer",
                            textDecoration: "underline",
                            fontSize: "22px",
                          }}
                          onClick={() => {
                            handleEdit(item);
                            scrollToSection();
                          }}
                        >
                          <BiSolidEditAlt />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default TrainingType;
